<template>
  <nav id="nav">
    <Content>
      <FlexContainer flex-direction="column">
        <FlexItem class="navigation__logo u__hide--mobile">
          <router-link to="/">
            <img
              src="~@/assets/images/logos/bupa/bupa-square.svg"
              height="70"
            />
          </router-link>
        </FlexItem>
        <FlexItem :grow="true">
          <ul>
            <li>
              <router-link to="/journeys/0/levels">
                Journey home
              </router-link>
            </li>
            <li>
              <router-link to="/leaderboard">
                Leaderboard
              </router-link>
            </li>
            <li>
              <router-link to="/ideas">
                Ideas wall
              </router-link>
            </li>
            <li>
              <router-link to="/account">
                My profile
              </router-link>
            </li>
            <!-- <li>
              <router-link to="/support">
                Support
              </router-link>
            </li> -->
            <li>
              <a @click="signOut">
                Logout
              </a>
            </li>
          </ul>
        </FlexItem>
        <FlexItem v-if="isSignedIn && currentUser.admin && false">
          <ul>
            <li v-if="currentUser.admin">
              <IconLink to="/admin" icon="cog" />
              <router-link to="/admin">
                Admin
              </router-link>
            </li>
          </ul>
        </FlexItem>
      </FlexContainer>
    </Content>
  </nav>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState({
      currentUser: state => state.user.currentUser
    }),
    ...mapGetters({
      isSignedIn: 'user/isSignedIn'
    })
  },
  methods: {
    ...mapActions({
      signOut: 'user/signOut'
    })
  }
}
</script>

<style lang="scss" scoped>
nav {
  font-family: 'Barlow', Helvetica, sans-serif;
  background-color: $color-white;
  border-right: 1px solid $color-gray;
  width: 100%;
  height: 100%;
  display: flex;
  padding: $spacing-m;
  justify-content: stretch;
  z-index: 10000;

  @include respond-below(sm) {
    padding: 0;
    display: none;
  }
}

nav ul {
  margin: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

nav ul li {
  flex: 0;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }

  a,
  button {
    background: none;
    outline: none;
    border: 0;
    color: $color-gray--dark;
    font-size: 21px;
    display: inline-block;
    height: inherit;
    line-height: 2;
    outline: 0;
    font-family: 'Barlow', Helvetica, sans-serif;

    &.router-link-exact-active {
      color: $color-primary;
    }
  }

  @include respond-below(sm) {
    display: block;
    padding: $padding-small 0;
    text-align: center;
  }
}

.navigation__logo {
  text-align: right;
  margin-bottom: 10rem;
}
</style>
