<template>
  <FlexContainer
    flex-direction="column"
    class="mobile-nav"
    :class="classes"
    :style="{ right: `-${screenWidth}px` }"
    justify-content="flex-start"
  >
    <FlexItem>
      <div class="mobile-nav-header-container">
        <img src="~@/assets/images/logos/bupa/bupa-square.svg" height="64" />
      </div>
    </FlexItem>
    <FlexItem :grow="true">
      <nav>
        <Content>
          <FlexContainer flex-direction="column">
            <FlexItem :grow="true">
              <ul>
                <li>
                  <router-link to="/journeys/0/levels">
                    Journey home
                  </router-link>
                </li>
                <li>
                  <router-link to="/leaderboard">
                    Leaderboard
                  </router-link>
                </li>
                <li>
                  <router-link to="/ideas">
                    Ideas wall
                  </router-link>
                </li>
                <li>
                  <router-link to="/account">
                    My profile
                  </router-link>
                </li>
                <!-- <li>
                  <router-link to="/support">
                    Support
                  </router-link>
                </li> -->
                <li>
                  <button @click="signOut">
                    Logout
                  </button>
                </li>
              </ul>
            </FlexItem>
            <FlexItem v-if="isSignedIn && currentUser.admin && false">
              <ul>
                <li v-if="currentUser.admin">
                  <IconLink to="/admin" icon="cog" />
                  <router-link to="/admin">
                    Admin
                  </router-link>
                </li>
              </ul>
            </FlexItem>
          </FlexContainer>
        </Content>
      </nav>
    </FlexItem>
    <FlexItem>
      <div class="mobile-nav-footer-container">
        <p><img src="~@/assets/images/turbine.svg" /> Powered by renewables</p>
        <p>&copy; Sustainabl. 2021</p>
      </div>
    </FlexItem>
  </FlexContainer>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState({
      open: state => state.app.isBurgerMenuOpen,
      currentUser: state => state.user.currentUser
    }),
    ...mapGetters({
      isSignedIn: 'user/isSignedIn'
    }),
    screenWidth() {
      return window.innerWidth
    },
    classes() {
      return {
        open: this.open
      }
    }
  },
  methods: {
    ...mapActions({
      signOut: 'user/signOut'
    })
  }
}
</script>

<style lang="scss" scoped>
.mobile-nav {
  font-family: 'Barlow', Helvetica, sans-serif;
  background-color: $color-secondary;
  width: 100%;
  height: 100vh;
  padding: $spacing-m;
  position: absolute;
  z-index: 10;
  top: 0;
  transition: 0.25s ease right;

  @include respond-above(sm) {
    display: none;
  }

  &.open {
    right: 0 !important;
  }
}

nav {
  padding-top: $padding-large;
}

nav ul {
  margin: 0;
}

nav ul li {
  display: block;
  text-align: left;

  &:last-of-type a {
    border-bottom: 0;
  }

  a,
  button {
    background: none;
    outline: none;
    border: 0;
    padding: $padding 0;
    color: $color-white;
    font-size: 2.4rem;
    display: block;
    height: inherit;
    line-height: 2;
    outline: 0;
    border-bottom: 1px solid #979797;
    display: block;
    width: 100%;
    text-align: left;

    &.router-link-exact-active {
      color: $color-primary;
    }
  }
}

.mobile-nav-header-container {
  text-align: center;
}

.mobile-nav-footer-container {
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  img {
    height: 17px;
    vertical-align: middle;
  }

  p {
    margin-bottom: 0;
  }
}

.burger {
  position: absolute;
  top: 2.4rem;
  right: 2.4rem;
}
</style>
