<template>
  <div class="icon">
    <IconLink
      v-if="!isSignedIn"
      to="/login"
      icon="user-circle"
      color="white"
      class="hideSpanOnMobile"
    >
      Login
    </IconLink>
    <div v-if="isSignedIn" class="account">
      <div class="username u__hide--mobile">
        {{ currentUser.name }}
      </div>
      <div class="account-icon">
        <figure>
          <router-link to="/account">
            <img v-if="currentUser.photoURL" :src="currentUser.photoURL" />
            <img v-else src="~@/assets/images/profile-female.svg" />
          </router-link>
        </figure>
        <!-- <span class="account-icon__points">5</span> -->
      </div>
      <Pill class="account-points u__hide--mobile">1820pts</Pill>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapState({
      currentUser: state => state.user.currentUser
    }),
    ...mapGetters({
      isSignedIn: 'user/isSignedIn'
    })
  }
}
</script>

<style lang="scss" scoped>
/deep/ .icon-link {
  font-size: 3rem;

  a {
    span {
      display: none;

      @include respond-above(md) {
        display: block;
      }
    }
  }
}

.account {
  display: flex;
  align-items: center;
}

.account-icon {
  position: relative;
  display: inline-block;

  &__points {
    position: absolute;
    right: -4px;
    top: -4px;
    background-color: #ff2d2d;
    color: #ffffff;
    font-size: 11px;
    line-height: 16px;
    height: 16px;
    border-radius: 16px;
    padding: 0 5px;
  }
}

.account-points {
  margin-left: 16px;
}

.username {
  display: inline-block;
  margin-right: 10px;
  font-size: 16px;
}
</style>
