<template>
  <header id="header">
    <FlexContainer flex-direction="row" class="header__container">
      <FlexItem padding="small" class="header__item">
        <AccountIcon />
      </FlexItem>
      <FlexItem size="25%" align-self="center" class="u__hide--desktop">
        <FlexContainer justify-content="center">
          <router-link to="/">
            <img
              src="~@/assets/images/logos/bupa/bupa-square.svg"
              height="48"
            />
          </router-link>
        </FlexContainer>
      </FlexItem>
      <FlexItem
        padding="small"
        class="header__item u__hide--desktop"
      ></FlexItem>
    </FlexContainer>
  </header>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import AccountIcon from '@/components/account/AccountIcon'

export default {
  components: {
    AccountIcon
  },
  computed: {
    ...mapState({
      currentUser: state => state.user.currentUser,
      isBurgerMenuOpen: state => state.app.isBurgerMenuOpen
    }),
    ...mapGetters({
      isSignedIn: 'user/isSignedIn'
    })
  }
}
</script>

<style lang="scss" scoped>
.header__container {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  flex-direction: row !important;
  justify-content: flex-end;
}

.header__item {
  padding: 1.6rem;
}

.header__item--logo {
  margin-right: 25%;

  @include respond-above(md) {
    margin-right: 0;
    justify-content: flex-end;
  }
}

.header__logo {
  display: block;
  height: 50px;
}

header {
  font-size: 2rem;
  width: 100%;
  z-index: 2;
  img {
    display: block;
    margin: auto;
    padding: 0;
  }

  @include respond-above(sm) {
    left: 0;
    position: absolute;
    top: 0;
  }
}
</style>
