<template>
  <button class="burger" :class="classes" @click="toggleMobileMenu">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      open: state => state.app.isBurgerMenuOpen
    }),
    classes() {
      return {
        open: this.open
      }
    }
  },
  methods: {
    ...mapActions({
      toggleMobileMenu: 'app/toggleMobileMenu'
    })
  }
}
</script>

<style lang="scss" scoped>
.burger {
  background: transparent;
  border: none;
  cursor: pointer;
  height: 19px;
  outline: none;
  position: absolute;
  right: 1.5rem;
  top: 2.1rem;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  width: 24px;
  z-index: 11;

  @include respond-above(sm) {
    display: none;
  }

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: $color-secondary;
    border-radius: 3px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 8px;
    }

    &:nth-child(4) {
      top: 16px;
    }
  }

  &.open span {
    background: $color-white;
    height: 2px;

    &:nth-child(1) {
      top: 3px;
      width: 0%;
      left: 50%;
    }

    &:nth-child(2) {
      transform: rotate(45deg);
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
    }

    &:nth-child(4) {
      top: 3px;
      width: 0%;
      left: 50%;
    }
  }
}
</style>
